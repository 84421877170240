import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
interface SectionProps {
    image : string;
    white?: boolean;
    headline : string;
    text : string;
    reversed? : boolean | null;
    center? : boolean;
    alt: string;
}
const Section : React.FC<SectionProps> = ({...props}) => {
  if(!props.center){
return (
  <div className="w-100 overflow-x-hidden">
    <div className={`${props.reversed ? "section-bg-reverse" : "section-bg"} ${props.white && "white"}`}>
    <div className="container">
      <div className="row">
        <div className={`col-12 ${!props.center ? "col-md-4" : ""} text-center ${!props.center ?  !props.reversed ? "text-md-end" : "text-md-start" : ""}`} >
          <LazyLoadImage className="img-fluid" src={props.image} alt={props.alt} effect="blur" />
        </div>
        <div className={`col-12 ${!props.center ? "col-md-8" : ""}`}>
          <h3 className={`text-center ${!props.center ?  !props.reversed ? "text-md-end" : "text-md-start" : ""} my-3`} dangerouslySetInnerHTML={{ __html: props.headline}} />
          <p className={`text-center ${!props.center ?  !props.reversed ? "text-md-end" : "text-md-start" : ""} my-3`} dangerouslySetInnerHTML={{ __html: props.text}} />
        </div>
      </div>
    </div>
  </div>
  </div>
);
  }
  return (
    <div className="w-100 overflow-x-hidden">
    <div className={`${props.reversed ? "section-bg-reverse" : "section-bg"} ${props.white && "white"}`}>
    <div className="container">
      <div className="row">
        <div className={`col-12 text-center`}>
          <h3 className={`text-center my-3`} dangerouslySetInnerHTML={{ __html: props.headline}} />
          <LazyLoadImage className="img-fluid" src={props.image} alt={props.alt} effect="blur" />
          <p className={`text-center  my-3`} dangerouslySetInnerHTML={{ __html: props.text}} />
        </div>
      </div>
    </div>
  </div>
  </div>
  );
}

export default Section;