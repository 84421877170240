import * as React from 'react';
import TopIcon from '../assets/images/expand_less_white_24dp.svg'

const ScrollToTopButton : React.FC = () => {
const [show, setShow] = React.useState(false);
const handleScroll = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
}

const listenScrollEvent = (event:any) => {
    if (window.scrollY < 150) {
      return setShow(false);
    } else if (window.scrollY > 150) {
        return setShow(true);
    } 
  }
  
  React.useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

    return (
    <div onClick={handleScroll} className={`scroll-top-btn ${show && "active"}`}>
        <img src={TopIcon} alt="top-icon" width="36" height="36"/>
    </div>);
}



export default ScrollToTopButton;