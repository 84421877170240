import React from 'react';
import Footer from './components/footer.component';
import Home from './pages/home';
import Datenschutz from './pages/data';
import Impressum from './pages/imprint';
import Contact from './pages/contact';
import ScrollToTop from "./scrollToTop";
import ScrollToTopButton from './components/scroll.component';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="site-wrapper">
        <Switch>
        <Route path="/kontakt" exact component={Contact} />
          <Route path="/datenschutz" exact component={Datenschutz} />
          <Route path="/impressum" exact component={Impressum} />
          <Route path="/" component={Home} />
        </Switch>
        <ScrollToTopButton />
        <Footer />
      </div>
    </Router>
  );
}

export default App;