import React from 'react';
import Helmet from 'react-helmet';
import Header from '../components/header.component';
import Section from '../components/section.component';
import LotusImage from '../assets/images/lotus.png';
import YingYangImage from '../assets/images/yin-und-yang.png';
import StoneImage from '../assets/images/stone-image.png';
import PfadImage from '../assets/images/text-pfad.png';
import AlexImage from '../assets/images/alex.png';
import Cha from '../assets/images/cha.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Home: React.FC = ({ ...props }) => {
  return (
    <>
      <Helmet>
        <title>Alexandra Olschewski Kinesiologin</title>
        <meta name="description" content="Alexandra Olschewski Kinesiologin aus Ennepetal" />
        <meta property="og:title" content="Alexandra Olschewski Kinesiologin" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://alexandra-olschewski.de" />
        <meta property="og:image" content={Cha} />
        <meta property="og:description" content="Alexandra Olschewski Kinesiologin aus Ennepetal" />
      </Helmet>
      <Header {...props} />
      <div className="main">
        <Section
          image={LotusImage}
          alt="Lotus"
          white={true}
          headline="Kein Hokuspokus…"
          text=" Kinesiologie ist keine Zauberei. Es geht bei der Kinesiologie um Dich und Deinen Körper. Keiner kennt dich besser auf dieser Welt, in der Kinesiologie testen wir Deinen Körper und hören zu."
          reversed={false} />
        <div className="w-100 special-section">
          <div className="container">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <div className="col-9 col-md-6">
                <h3 className="my-4">Im Einklang mit Geist und Körper</h3>
                <p>Nicht jeder Körper verträgt das gleiche, lass uns gemeinsam testen was er verträgt und braucht. Eine gesunde und aus gewogene Ernährung sind Bestandteil Deines selbstheilungsprozesses.</p>
              </div>
            </div>
          </div>
          <img src={PfadImage} className="pfad" alt="pfad" />
          <LazyLoadImage src={StoneImage} alt="stones" effect="blur" />
        </div>
        <Section
          image={YingYangImage}
          alt="Ying-und-Yang"
          white={true}
          center={true}
          headline="Der Energiefluss des Körpers"
          text="<b>Erde, Feuer, Wasser, Metall und Holz die MERIDIANE  <br />
            Die uns umgeben und unser Leben beeinflussen.</b> <br /> <br />Schon im Alten China praktizierten sie die Technik der Akupunktur, sie stimuliert einzelnen Meridiane und regt den Selbstheilungsprozess an."
          reversed={true} />
        <Section
          image={AlexImage}
          alt="Alexandra Olschweski"
          white={false}
          headline="Hallo ich bin Alexandra… <br /><small>44 Jahre alt kinesiologin, Ehefrau und Mama.</small>"
          text="Die Leidenschaft anderern Menschen zur helfen began
            sehr früh in meiner Kindheit. Es war mir immer wichtig menschen um 
            mir herum zu Helfen und zu unterstützen. Durch die Kinesiologie lernte
            ich auf den Körper und den Geist einzugehen und anderen Mitmenschen 
            Bei Ihren Problemen zu unterstützen. Als Ehefrau und Mama kenne ich
            den Alltag-stress der uns täglich umgibt, lerne mit ihm umzugehen 
            und deine Vorteile draus zu zeihen."
          reversed={true} />
      </div>
    </>
  );
}

export default Home;