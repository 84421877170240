import React from 'react';
import Header from '../components/header.component';
const Imprint: React.FC = () => {
    return (
        <>
            <Header singlepage />
            <div className="main single-page">
                <div className="container">
                    <h1>Impressum</h1>
                    <h2>Angaben gemäß § 5 TMG</h2>
                    <br />
                    <br />
                    <p>Alexandra Olschewski <br />
                        yyy <br />
                        58256 Ennepetal</p>
                    <p></p>
                    <h3>Kontakt</h3>
                    <p>Telefon:  <a className="phone" href="tel:+491777289577">+49 (0) 177 7289577</a> <br />
                        E-Mail: <a href="mailto:info@alexandra-olschweski.de">info@alexandra-olschweski.de</a>
                    </p>
                    <h3>Verantwortlicher</h3>
                    <p>Inhaltlich Verantwortlicher gemäß § 18 Abs. 2 MStV: Alexandra Olschewski</p>
                    <h3> Hinweise: </h3>
                    <p>
                        Kleinunternehmer i. S. v. § 19 Abs. 1 UStG haben ein Wahlrecht, ob sie Umsatzsteuer abführen wollen. Entscheiden sie sich gegen die Abführung, benötigen sie grundsätzlich keine Umsatzsteuer-Identifikationsnummer. Verfügen sie über keine Umsatzsteuer-Identifikationsnummer, müssen sie auch im Impressum keine solche angeben. Hinzu kommt, daß Kleinunternehmer häufig nicht ins Handelsregister eingetragen sind, so daß auch insoweit keine Angaben erforderlich sind. Besteht allerdings eine Registereintragung oder wurde eine Umsatzsteuer-Identifikationsnummer erteilt, sind die entsprechenden Angaben zu machen. Das Muster geht davon aus, daß keine Umsatzsteuer-Identifikationsnummer vorliegt und daß der Betreiber der Internetseite nicht ins Handelsregister eingetragen ist. Für das Muster wurde ferner von einem journalistisch-redaktionell gestalteten Angebot ausgegangen, so daß die Anbieterkennzeichnung nach § 18 Abs. 2 MStV gesondert aufgenommen wurde.
                    </p>
                </div>
            </div>
        </>
    );
}

export default Imprint;