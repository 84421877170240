import React from 'react';
import {Link} from 'react-router-dom';

const Footer : React.FC = () => {
return (
<div className="w-100 overflow-x-hidden">
<footer className="footer">
    <div className="container">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
        <div className="col-12 col-md-6 my-4">
        <ul>
            <li><Link to="/kontakt">Kontakt</Link></li>
            <li><Link to="/impressum">Impressum</Link></li>
            <li><Link to="/datenschutz">Datenschutz</Link></li>
        </ul>
        </div>
        <div className="col-12 col-md-6">
            <p className="disclaimer text-center text-md-end">mit ❤️erstellt von <b><a target="_blank" rel="noreferrer" href="https://examplesart.de/">examplesart</a></b></p>
        </div>
        </div>
    </div>
</footer>
</div>
);

}

export default Footer;