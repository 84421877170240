import React from 'react';
import FigureOne from '../assets/images/obj1.svg';
import Cha from '../assets/images/cha.png';
import Menu from '../assets/images/menu.svg';
import WhatsApp from '../assets/images/whatsapp_black_24dp.svg'
import CloseIcon from '../assets/images/highlight_off_white_24dp.svg'


import { Link, useHistory } from 'react-router-dom';

interface HeaderProps {
    singlepage?: boolean;
}
const Header: React.FC<HeaderProps> = ({ singlepage, ...props }) => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    return (
        <header className="header">
            <img src={FigureOne} className="figure-header" alt="figure-one" />
            <img className="figure-next" src={Cha} alt="cha-item" />
            <div className="container wrapper">
                <div className="row">
                    <div className="col-12 mt-4">
                        <div className="d-flex flex-row justify-content-start position-relative">
                            <div className="col">
                                <img className="menu" src={Menu} alt="navigation-menu" width="36" height="36" onClick={() => setOpen(!open)} />
                                <a className="whatsapp" rel="noreferrer" href="https://wa.me/+491777289577" target="_blank"> <img className="" src={WhatsApp} alt="WhatsApp" width="36" height="36" /></a>
                                <a className="phone" href="tel:+491777289577">+49 (0) 177 7289577</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 figure-content">
                        <h1 onClick={() => history.replace("/")} ><b>alexandra</b>Olschewski</h1>
                        <h2 onClick={() => history.replace("/")}>…testen, was los IST.</h2>
                    </div>
                </div>
            </div>
            {!singlepage &&
                <div className="container info">
                    <h3 className="my-3">Kinesiologie Dein Zentrum zu Dir.</h3>
                    <p>Wer könnte besser wissen, was Du brauchst als Dein eigener Körper, <br />
                        gemeinsam testen wir, was ihm gut tut, <br />
                        denn <b>Dein Körper weiß, was gut für ihn IST.</b> </p>
                </div>
            }
            <div className={`drawer-menu ${open && "active"}`}>
                <div className="container position-relative">
                    <div className="close" onClick={() => setOpen(!open)}>
                        <img src={CloseIcon} width="42" height="42" alt="close-icon" />
                    </div>
                    <ul>
                        <li><Link to="/" onClick={() => setOpen(!open)}>Startseite</Link> </li>
                        <li><Link to="/kontakt" onClick={() => setOpen(!open)}>Kontakt</Link> </li>
                        <li><Link to="/datenschutz" onClick={() => setOpen(!open)}>Datenschutz</Link> </li>
                        <li><Link to="/impressum" onClick={() => setOpen(!open)}>Impressum</Link> </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;