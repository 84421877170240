import React from 'react';
import Header from '../components/header.component';
const Contact: React.FC = () => {
    return (
        <>
            <Header singlepage />
            <div className="main single-page">
                <div className="container">
                    <h1 className="mb-5">Kontakt</h1>
                    <p className="mb-2">E-Mail: <a href="mailti:info@alexandra-olschweski.de">info@alexandra-olschweski.de</a></p>
                    <p className="mb-2">Mobile: <a href="tel:+49 (0) 177 7289577">+49 (0) 177 7289577</a></p>
                    <p>Telefonisch erreichbar: Mo-Fr: 10:00 - 16:00 Uhr </p>
                </div>
            </div>
        </>
    );
}

export default Contact;